@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap");
.keydetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.keydetails-left-wrapper {
  position: relative;
}
.keydetails-right-form-btn-container {
  display: flex;
  justify-content: space-evenly;
  gap: 1em;
}

.keydetails-right-form-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em;
  width: 100%;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 4px 5px -3px rgba(0, 0, 0, 0.75);
}

.keydetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}
.keydetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
.keydetails-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}
.keydetails-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}
.keydetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.keydetails-sidebar-menu-ul-hide {
  display: none;
}
.keydetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.keydetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}
.keydetails-right-wrapper {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.keydetails-right-wrapper > p {
  text-align: center;
}
.keydetails-right-form {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.keydetails-right-form-label-input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.keydetails-right-form-select,
.keydetails-right-form-inputs {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
}
@media screen and (min-width: 48em) {
  .keydetails-right-wrapper {
    margin: 0 5rem;
    align-items: center;
  }
  .keydetails-right-form-select,
  .keydetails-right-form-inputs {
    max-width: 15em;
  }
  .keydetails-right-form-btn-container {
    max-width: 25em;
  }
}
@media screen and (min-width: 62.5em) {
  .keydetails-sidebar-toggle-btn-wrapper {
    display: none;
  }
  .keydetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}
@media screen and (width: 64em) {
  .keydetails-right-wrapper {
    margin-top: 1.5em;
    margin-left: 15em;
  }
}
@media screen and (min-width: 75em) {
  .keydetails-right-wrapper {
    margin-left: 17em;
    padding: 2% 11%;
  }
}
@media screen and (min-width: 78.125em) {
  .keydetails-sidebar-menu-link {
    font-size: 1rem;
  }
  .keydetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
}

/* .body-login{
    width: 85%;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    margin-top: 15px;
  }
  
  .image-upload{
    width: 120px;
    height: 120px;
    margin-top:2rem ;
    margin-bottom: 3rem;
  }
  .add_img_btn{
    border: none;
    background-color:#e7e4e4;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 3rem;
  }
  .add_img_btn:hover{
    cursor: pointer;
    background-color: rgb(251 249 249);
  }

  #choose-profile-imageActi{
      display: none;
  }
  
  .key-activation input{
    padding: 0px !important;
  }
  
  select{
    padding: 0px !important;
  }
  
  .mat-raised-button:disabled{
    background-color:#FCBC1B;
  }
  
  .image{
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  
  .overlay{
    height: 120px;
    width: 120px;
    background-color: black;
    margin-top: -120px;
    position: absolute;
    opacity:0;
    border-radius: 50%;
  }
  .button-image{
    background-color: transparent;
    border: none;
    outline: none;
  }
  .fa-edit{
    margin-top:48px;
    color: white;
  }
  
  .plus-icon{
    margin-top: 0px;
    color: #a1a1a1;
  }
  
  .image-upload:hover .overlay{
    background-color: rgba(0,0,0,0.5);
    opacity:1;
  }

  .leftside-navigation{
    list-style: none; 
    margin: auto; 
    margin-left: -30px;
  }
  
  #uploadPreviewActi{
    width: 120px;
    height: 120px;
    margin-top: -148px !important;
    border-radius: 50%;
    border:none;
    outline:none;
  }
  
  #uploadPreview{
    width: 120px;
    height: 120px;
    margin-top: -140px !important;
    border-radius: 50%;
    display: none;
    border:none;
    outline:none;
  }
  
  
  .left-side{
    border-radius: 15px 0 0 15px;
    background-color: rgb(255, 234, 177);
  }
  
  .row-underline{
    border-bottom: 5px solid #FCBC1B;
  }

  textarea{
      min-height: 20px !important;
      border: 2px solid #e4e4e4;
      width: 200px !important;
      font-size: 14px;
      outline: none !important;
      box-shadow: none !important;
  }

  textarea:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 2px solid #e4e4e4 !important;
  }
  
  .input-pet-details{
    border: none;
    outline: none !important;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 200px;
    height: 20px;
    font-size: 14px;
  }
  
  .input-pet-details-1{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 440px;
    height: 20px;
    font-size: 14px;
  }
  
  .input-pet-details-2{
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
  }
  
  .input-pet-details-dropdown{
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
  }
  
  .label-petdata{
    font-size: 16px;
    color: #494949;
    font-weight: 800;
    font-family: "Lato", sans-serif;
  } */

/* .label-petdata-1{
  font-size: 12px;
  color: #777777;
  font-size: 16px;
    color: #494949;
    font-weight: 800;
    font-family: "Lato", sans-serif;
  }
  
  p strong{
  font-size: 18px;
  }
  
  .color-gray{
    color:  #b4b4b4;
  }
  
  ::ng-deep .mat-radio-label-content {
    font-weight: 550 !important;
  }
  
  input:focus{
    border: none !important;
    outline: none !important;
    border-bottom: 2px solid #e4e4e4 !important;
    box-shadow: none !important;
    outline-width: 0 !important;
  }
  
  input:focus,
  select:focus,
  button:focus {
    outline: none !important;
  }
  
  input[readonly] {
    background-color: white;
  }
  
  .submit-login{
    width: 100%;
    max-height: 35px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .file{
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #FCBC1B;
    border-radius:5px;
    width:100px;
    height: 30px;
    font-size: 13px;
  }
  
  .gender-male{
      font-weight: none;
      font-size: 13px;
  }
  
  .gender-female{
  font-weight: none;
  font-size: 13px;
  }
  
  .multiple-images{
  transition: transform .3s;
  }
  
  .multiple-images:hover{
  transform: scale(2.5);
  }

  @media only screen and (max-width:990px){
    .body-login{
      width: 100%;
      margin-top: 50px !important;
    }
    .marginheight{
      margin-top: 0px !important;
    }
    .dashboard__blacksidenav{
      height: 100% !important;
      width: 100%;
    }
    .petparentlinks{
      margin-top: 20px !important;
      margin-left: -10px !important;
    }
  }
  
  
  @media only screen and (max-width:850px){
  .body-login{
    width: 100%;
  }
  }
  
  @media only screen and (max-width:770px){
  .label-petdata{
    text-align: center;
  }
  .label-petdata-1{
    text-align: center;
  }
  
  .gender-female{
    margin-top: -40px !important;
  }
  .below-gender-move{
    margin-top: 40px;
  }
  }
  
  @media only screen and (max-width:576px){
  .below-gender-move{
    margin-top: 0px;
  }
  .body-login{
    border-radius: 0px;
  }
  .left-side{
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons{
    margin-left: 0px !important;
  }
  .petparentlinks{
    margin-left: -10px !important;
    margin-top: 20px !important;
  }
  }

  @media only screen and (max-width:510px){
    .marginheight{
      margin-top: 30px !important;
    }
  }
  
  @media only screen and (max-width:360px){
    .input-pet-details{
      width: 200px;
    }
    
    .input-pet-details-2{
    width: 200px;
    }
    
    .input-pet-details-dropdown{
    width: 200px;
    }
    .submit-login{
      width: 200px !important;
    }
    }
    
    @media only screen and (max-width:330px){
      .gender-female{
        margin-top: -40px !important;
      }
      .below-gender-move{
        margin-top: 40px;
      }
      .leftside-buttons{
        width: 160px !important;
      }
      .petparentlinks{
        margin-left: -20px !important;
      }
      }
    
    @media only screen and (max-width:300px){
    .input-pet-details{
      width: 170px;
    }
    
    .input-pet-details-2{
    width: 170px;
    }
    
    .input-pet-details-dropdown{
    width: 170px;
    }
    }
   */
.keydetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.keydetails-left-wrapper {
  position: relative;
}

.keydetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}
.keydetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
.keydetails-right-form-btn-container {
  display: flex;
  /* justify-content: space-evenly;
     */
  padding: 0rem 4rem 0rem 3rem;
  gap: 1em;
}

.editPetDetails-user-img {
  text-align: center;
  position: relative;
}

.editPetDetails-image {
  width: 15rem;
  height: 15rem;
  margin-bottom: 2rem;
  /* border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
}

.editPetDetails-edit-btn-wrap {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}

.editPetDetails-edit-button-image,
.editPetDetails-delete-button-image {
  display: inline-block;
  padding: 5px 10px;
  color: #000;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid lightgray;
  transition: background-color 0.3s;
}
.editPetDetails-edit-button-image:hover,
.editPetDetails-delete-button-image:hover {
  background-color: #fcbc1b;
  color: white;
}

.editPetDetails-input-user-img {
  display: none;
}

.editPetDetails-preview-img {
  margin-top: 10px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

/* p {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
  } */

.keydetails-right-form-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em;
  width: 100%;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 4px 5px -3px rgba(0, 0, 0, 0.75);
}

.keydetails-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}
.keydetails-sidebar-container {
  width: 60%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}
.keydetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.keydetails-sidebar-menu-ul-hide {
  display: none;
}
.keydetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.keydetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}
.background-container {
  /* background-image: url("../../assets/grey.png"); */
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-image-details {
  background: linear-gradient(to bottom, #fffdfd, #f4ebeceb, #f1f1f1cc);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
}
.keydetails-right-wrapper {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1em; */
}

.keydetails-right-wrapper > p {
  text-align: center;
}
.keydetails-right-form {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.keydetails-right-form-label-input-container {
  display: flex;
  flex-direction: column;
  /* gap: 0.5em; */
}

.keydetails-right-form-select,
.keydetails-right-form-inputs {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
}
@media screen and (min-width: 48em) {
  /* .keydetails-right-wrapper {
      margin: 0 5rem;
      align-items: center;
    } */
  .keydetails-right-form-select,
  .keydetails-right-form-inputs {
    max-width: 15em;
  }
  .keydetails-right-form-btn-container {
    max-width: 25em;
  }
}
@media screen and (min-width: 62.5em) {
  .keydetails-sidebar-toggle-btn-wrapper {
    display: none;
  }
  .keydetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
    font-family: "prompt", sans-serif;
  }
}
/* @media screen and (width: 64em) {
    .keydetails-right-wrapper {
      margin-top: 1.5em;
      margin-left: 15em;
    }
  } */
/* @media screen and (min-width: 75em) {
    .keydetails-right-wrapper {
      margin-left: 17em;
      padding: 2% 11%;
    }
  } */
@media screen and (min-width: 78.125em) {
  .keydetails-sidebar-menu-link {
    font-size: 1rem;
  }
  .keydetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
}

/* .body-login{
      width: 85%;
      border-radius: 15px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
      margin-top: 15px;
    }
    
    .image-upload{
      width: 120px;
      height: 120px;
      margin-top:2rem ;
      margin-bottom: 3rem;
    }
    .add_img_btn{
      border: none;
      background-color:#e7e4e4;
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: left;
      align-items: center;
      padding-left: 3rem;
    }
    .add_img_btn:hover{
      cursor: pointer;
      background-color: rgb(251 249 249);
    }
  
    #choose-profile-imageActi{
        display: none;
    }
    
    .key-activation input{
      padding: 0px !important;
    }
    
    select{
      padding: 0px !important;
    }
    
    .mat-raised-button:disabled{
      background-color:#FCBC1B;
    }
    
    .image{
      height: 120px;
      width: 120px;
      border-radius: 50%;
    }
    
    .overlay{
      height: 120px;
      width: 120px;
      background-color: black;
      margin-top: -120px;
      position: absolute;
      opacity:0;
      border-radius: 50%;
    }
    .button-image{
      background-color: transparent;
      border: none;
      outline: none;
    }
    .fa-edit{
      margin-top:48px;
      color: white;
    }
    
    .plus-icon{
      margin-top: 0px;
      color: #a1a1a1;
    }
    
    .image-upload:hover .overlay{
      background-color: rgba(0,0,0,0.5);
      opacity:1;
    }
  
    .leftside-navigation{
      list-style: none; 
      margin: auto; 
      margin-left: -30px;
    }
    
    #uploadPreviewActi{
      width: 120px;
      height: 120px;
      margin-top: -148px !important;
      border-radius: 50%;
      border:none;
      outline:none;
    }
    
    #uploadPreview{
      width: 120px;
      height: 120px;
      margin-top: -140px !important;
      border-radius: 50%;
      display: none;
      border:none;
      outline:none;
    }
    
    
    .left-side{
      border-radius: 15px 0 0 15px;
      background-color: rgb(255, 234, 177);
    }
    
    .row-underline{
      border-bottom: 5px solid #FCBC1B;
    }
  
    textarea{
        min-height: 20px !important;
        border: 2px solid #e4e4e4;
        width: 200px !important;
        font-size: 14px;
        outline: none !important;
        box-shadow: none !important;
    }
  
    textarea:focus{
      outline: none !important;
      box-shadow: none !important;
      border: 2px solid #e4e4e4 !important;
    }
    
    .input-pet-details{
      border: none;
      outline: none !important;
      border-bottom: 2px solid #e4e4e4;
      box-shadow: none;
      outline-width: 0;
      width: 200px;
      height: 20px;
      font-size: 14px;
    }
    
    .input-pet-details-1{
      border: none;
      outline: none;
      border-bottom: 2px solid #e4e4e4;
      box-shadow: none;
      outline-width: 0;
      width: 440px;
      height: 20px;
      font-size: 14px;
    }
    
    .input-pet-details-2{
    border: none;
    outline: none;
    border-bottom: 2px solid #a1a1a1;
    box-shadow: none;
    outline-width: 0;
    width: 200px;
    height: 20px;
    font-size: 14px;
    background-color: rgb(255, 234, 177);
    }
    
    .input-pet-details-dropdown{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    width: 200px;
    height: 20px;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
    }
    
    .label-petdata{
      font-size: 16px;
      color: #494949;
      font-weight: 800;
      font-family: "Lato", sans-serif;
    } */

/* .label-petdata-1{
    font-size: 12px;
    color: #777777;
    font-size: 16px;
      color: #494949;
      font-weight: 800;
      font-family: "Lato", sans-serif;
    }
    
    p strong{
    font-size: 18px;
    }
    
    .color-gray{
      color:  #b4b4b4;
    }
    
    ::ng-deep .mat-radio-label-content {
      font-weight: 550 !important;
    }
    
    input:focus{
      border: none !important;
      outline: none !important;
      border-bottom: 2px solid #e4e4e4 !important;
      box-shadow: none !important;
      outline-width: 0 !important;
    }
    
    input:focus,
    select:focus,
    button:focus {
      outline: none !important;
    }
    
    input[readonly] {
      background-color: white;
    }
    
    .submit-login{
      width: 100%;
      max-height: 35px;
      border: none;
      outline: none;
      border-radius: 5px;
      font-weight: 500;
    }
    
    .file{
      border: none;
      outline: none;
      box-shadow: none;
      background-color: #FCBC1B;
      border-radius:5px;
      width:100px;
      height: 30px;
      font-size: 13px;
    }
    
    .gender-male{
        font-weight: none;
        font-size: 13px;
    }
    
    .gender-female{
    font-weight: none;
    font-size: 13px;
    }
    
    .multiple-images{
    transition: transform .3s;
    }
    
    .multiple-images:hover{
    transform: scale(2.5);
    }
  
    @media only screen and (max-width:990px){
      .body-login{
        width: 100%;
        margin-top: 50px !important;
      }
      .marginheight{
        margin-top: 0px !important;
      }
      .dashboard__blacksidenav{
        height: 100% !important;
        width: 100%;
      }
      .petparentlinks{
        margin-top: 20px !important;
        margin-left: -10px !important;
      }
    }
    
    
    @media only screen and (max-width:850px){
    .body-login{
      width: 100%;
    }
    }
    
    @media only screen and (max-width:770px){
    .label-petdata{
      text-align: center;
    }
    .label-petdata-1{
      text-align: center;
    }
    
    .gender-female{
      margin-top: -40px !important;
    }
    .below-gender-move{
      margin-top: 40px;
    }
    }
    
    @media only screen and (max-width:576px){
    .below-gender-move{
      margin-top: 0px;
    }
    .body-login{
      border-radius: 0px;
    }
    .left-side{
      border-radius: 0px 0 0 0px;
    }
    .leftside-buttons{
      margin-left: 0px !important;
    }
    .petparentlinks{
      margin-left: -10px !important;
      margin-top: 20px !important;
    }
    }
  
    @media only screen and (max-width:510px){
      .marginheight{
        margin-top: 30px !important;
      }
    }
    
    @media only screen and (max-width:360px){
      .input-pet-details{
        width: 200px;
      }
      
      .input-pet-details-2{
      width: 200px;
      }
      
      .input-pet-details-dropdown{
      width: 200px;
      }
      .submit-login{
        width: 200px !important;
      }
      }
      
      @media only screen and (max-width:330px){
        .gender-female{
          margin-top: -40px !important;
        }
        .below-gender-move{
          margin-top: 40px;
        }
        .leftside-buttons{
          width: 160px !important;
        }
        .petparentlinks{
          margin-left: -20px !important;
        }
        }
      
      @media only screen and (max-width:300px){
      .input-pet-details{
        width: 170px;
      }
      
      .input-pet-details-2{
      width: 170px;
      }
      
      .input-pet-details-dropdown{
      width: 170px;
      }
      }
     */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
